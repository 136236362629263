body {
  margin: 0;
  padding: 0;
   background-color: '#131313';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.word {
  display: block;
}

.word span {
  color: #333;
  position: relative;
}

.word span::after {
  content: "";
  width: 8px;
  height: 100%;
  background: #DF5013;
  display: block;
  position: absolute;
  right: -10px;
  top: 0;
  animation: blink 0.5s ease infinite alternate-reverse;
}

@keyframes blink {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}

.word.empty {
  visibility: hidden;
}

.word.empty span::after {
  visibility: visible;
  right: 0;
}

#debug {
  position: fixed;
  top: 1em;
  left: 1em;
}
#debug::after {
  content: " Show Debug";
  margin-left: 1.5em;
  color: white;
  white-space: nowrap;
}

#debug:checked ~ main {
  --outline-width: 1px;
}


* { /* Poor Man's Reset */
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}



header,
main {
	width: 80vw;
	margin: 0 auto;
}



/* swiper */
.swiper-pagination{
	position: relative !important;
	margin-top: 20px !important;
}
.swiper-pagination-bullet{
	background: white !important;
	height: 1.5px !important;
	width: 22px !important;
	border-radius: 0 !important;
	opacity: 1 !important;
	

}
.swiper-pagination-bullet-active{
	height: 2px !important;
	width: 24px !important;
	background: grey !important;
	position: relative !important;
	
}

.swiper-button-next{
	right: 0 !important;
	display: flex;
	bottom: 0 !important;
	justify-content: end;
}
.swiper-button-prev{
	position: relative !important;
}
